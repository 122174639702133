<template>
    <div>
        <section class='trbaner'>
            <div class="foxj">
                <div class='am-container'>
                    <div class='txt'>
                        <div class="am-vertical-align-middle">
                            <p class="tit">产品和技术是我们发展的根基</p>
                            <p class="note">用心成就品质，从设计、选料、工艺到生产和检测，我们自始至终坚持精益求精，每一步都用心，只为给您最可靠稳定的产品体验</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src='~images/产品中心.png'>
        </section>
        <section class="am-path">
            <div class="am-container">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/product' }">产品中心</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </section>
        <section class="nybox">
            <div class="am-container">
                <div class="nyright">
                    <div class="nyplist">
                        <ul class="am-avg-md-3">
                            <!-- <li>
                            <a href='/views/product-1'>
                            <i> <img src='~images/500 (1).jpg'></i>
                            <div class="txt">
                                <p class="tit" >综合监控服务器（高配型）</p>
                                <p class="note">RZY-8800</p>
                            </div>
                            </a>
                        </li> -->
                            <li v-for="(item2, index) in dataList" :key='index'>
                                <a @click="func(item2)">
                                    <i> <img :src="/dqcsys/ + item2.furl"></i>
                                    <div class="txt">
                                        <p class="tit">{{ item2.product_name }}</p>
                                        <p class="note">{{ item2.product_model }}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="block">
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :page-size="9" layout=" prev, pager, next" :total="this.total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <div class="nyleft">
                    <el-col>
                        <p class="tit">产品中心</p>
                        <el-menu default-active="2" class="el-menu-vertical-demo">
                            <el-menu-item v-for='(item1, index) in data' :key='index' @click="getTree(item1)"><a>{{
                                item1.resultMsg }}</a></el-menu-item>
                            <!-- <el-submenu index='1' v-for='(item,index) in data' :key="index">
                    <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{item.resultMsg}}</span>
                    </template>
<el-menu-item-group>
</el-menu-item-group>
</el-submenu> -->
                        </el-menu>
                    </el-col>
                </div>
            </div>
        </section>
        <!-- <section class="homed">
          <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=77673175&site=qq&menu=yes"><img border="0" src="http://wpa.qq.com/pa?p=2::51" alt="点击这里给我发消息" title="点击这里给我发消息"/></a>

        </section>     -->
    </div>
</template>
<script>
import { getList } from 'api/product'
import { getTreeList } from 'api/proClass'
export default {
    data() {
        return {
            dataList: [],
            data: [],
            searchInfo: {
                page_no: 1,
                page_size: 9,
                product_class: 1,
            },
            total: []
        }
    },
    methods: {
        getList() {
            this.isShowloading = true;
            this.searchInfo.product_class = this.$route.params.product_class;
            getList(this.searchInfo).then((res) => {
                console.log(res);
                this.dataList = res.data.list;
                this.total = res.data.entity.all_count
                console.log(this.dataList)
            });
        },
        getTree(item1) {
            this.isShowloading = true;
            this.searchInfo.product_class = item1.resultMsg;
            this.searchInfo.page_no = 1;
            this.searchInfo.page_size = 9;
            getList(this.searchInfo).then((res) => {
                this.total = res.data.entity.all_count
                this.dataList = res.data.list;
                console.log(this.dataList)
            });
        },
        getTreeList() {
            this.isShowloading = true;
            console.log(this.searchInfo);
            getTreeList(this.searchInfo).then((res) => {
                console.log(res);
                this.data = res.data.list;
                console.log(this.data)
            });
        },
        func(item2) {
            let pathInfo = this.$router.resolve({
                path: '/product-2.vue',
                query: {
                    id: item2.fuid
                }
            })
            window.open(pathInfo, '_blank')
            // this.$router.push({ name: 'product-2', params: { id: item2.fuid } });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.searchInfo.page_no = val
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.searchInfo.page_no = val
            this.getList()

        },
    },
    mounted() {
        this.getList()
        this.getTreeList()
    }

}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}

.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}

.trbaner .foxj {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}

.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}

.foxj .txt {
    position: absolute;
    text-align: left;
    right: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}

.foxj .txt .tit {
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}

.foxj .note {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}

.am-container::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.foxj .txt .am-vertical-align-middle {
    position: relative;
    top: 30%;
}

.am-path {
    height: 50px;

    .el-breadcrumb {
        border: 1px solid #eee;
        font-size: 14px;
        line-height: 50px;
        height: 50px;
        padding-left: 20px;
    }
}

.nybox {
    padding: 30px 0;
    background-color: #f5f5f5;

    .nyleft {
        width: 254px;
        height: 100%;

        .tit {
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }

        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }

    .nyright {
        width: 77%;
        float: right;

        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;

            li {
                padding: 10px 0;
                list-style: none;
                width: 30%;

                a {
                    display: block;
                    background: #fff;
                    text-align: center;
                    border: 1px solid #fff;
                    overflow: hidden;

                    i {
                        overflow: hidden;
                    }

                    img {
                        height: 175px;
                        transition: all 0.7s;
                    }

                    img:hover {
                        transform: scale(1.2);
                    }

                    .txt {
                        padding: 20px;

                        .tit {
                            font-size: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 4px;
                            color: #333;
                        }

                        .note {
                            font-size: 12px;
                            margin-bottom: 6px;
                            color: #999;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                    }
                }

                a:hover {
                    border: 1px solid #0e90d2
                }
            }
        }
    }
}

.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>